import React, { useEffect, useState } from "react";

const MONDAY_API_KEY = "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjQ4MjcxMjc1MiwiYWFpIjoxMSwidWlkIjo2NTEzOTUyOCwiaWFkIjoiMjAyNS0wMy0wOFQwNjowNDoyMi4xOTBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MjUwNjY0ODAsInJnbiI6ImFwc2UyIn0.Y4a-JiTBQRNx9A1MA7jcdjNSaldj_YtRYr1OPWs6dGk"; // Replace with your actual key
const BOARD_ID = "1982765426"; // Your Board ID

const fetchTasks = async () => {
  console.log("🚀 Fetching tasks from Monday.com...");

  const query = {
    query: `query {
      boards(ids: ${BOARD_ID}) {
        items_page {
          items {
            id
            name
            column_values {
              id
              text
              value
            }
          }
        }
      }
    }`
  };

  try {
    const response = await fetch("https://api.monday.com/v2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${MONDAY_API_KEY}`,
      },
      body: JSON.stringify(query),
    });

    const data = await response.json();
    console.log("📊 API Data:", data);

    if (!data || !data.data || !data.data.boards) {
      console.error("❌ Invalid API response:", data);
      return [];
    }

    return data.data.boards[0].items_page.items;
  } catch (error) {
    console.error("❌ Error fetching tasks:", error);
    return [];
  }
};

export default function MondayTasks() {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    fetchTasks().then(setTasks);
  }, []);

  return (
    <div style={{ 
      padding: "20px", 
      backgroundColor: "#e28743", 
      minHeight: "100vh", 
      textAlign: "center" 
    }}>
      <h1 style={{ fontSize: "3rem", fontWeight: "bold", marginBottom: "10px" }}>The Wait = The Weight</h1>
      <h2 style={{ fontSize: "1.5rem", marginBottom: "20px" }}>Let's Act!</h2>

      {/* Background container properly centered */}
      <div style={{ 
        maxWidth: "500px", 
        margin: "0 auto", // ✅ This centers it 
        padding: "20px", 
        backgroundColor: "#f4a261", 
        borderRadius: "15px", 
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" 
      }}>
        {tasks.length > 0 ? (
          tasks.map((task) => {
            const daysColumn = task.column_values.find(col => col.id === "numeric_mknv6354"); // "Days Since" column
            let days = daysColumn && daysColumn.text ? parseInt(daysColumn.text) : null;

            // If the column gives a negative value, convert to positive
            if (days !== null && days < 0) {
              days = Math.abs(days);
            }

            // If days is still null, calculate manually from the "Date" column
            if (days === null) {
              const dateColumn = task.column_values.find(col => col.id.startsWith("date")); // "Date" column
              if (dateColumn && dateColumn.text) {
                const taskDate = new Date(dateColumn.text);
                const today = new Date();
                const diffTime = today - taskDate;
                days = Math.floor(diffTime / (1000 * 60 * 60 * 24)); // Convert to days
              }
            }

            // Assign color based on days
            let boxColor = "#34d399"; // Green (default)
            if (days >= 3 && days <= 5) {
              boxColor = "#facc15"; // Orange
            } else if (days >= 6) {
              boxColor = "#ef4444"; // Red
            }

            return (
              <div
                key={task.id}
                style={{
                  backgroundColor: boxColor,
                  padding: "15px",
                  marginBottom: "10px",
                  borderRadius: "10px",
                  cursor: "pointer",
                  width: "90%", // ✅ Slightly smaller width to fit inside the container
                  marginLeft: "auto", // ✅ Centers the task box inside the container
                  marginRight: "auto", // ✅ Centers the task box inside the container
                  textAlign: "center"
                }}                
                onClick={() => window.open(`https://lukefullagars-team.monday.com/boards/${BOARD_ID}/pulses/${task.id}`)}
              >
                <p style={{ fontSize: "1.2rem", fontWeight: "bold", color: "white" }}>{task.name}</p>
                <p style={{ fontSize: "0.9rem", fontStyle: "italic", color: "white" }}>Been Waiting For {days} Days</p>
              </div>
            );
          })
        ) : (
          <p style={{ textAlign: "center", color: "white" }}>Loading tasks...</p>
        )}
      </div>
    </div>
  );
}
