import React, { useEffect } from "react";
import MondayTasks from "./components/MondayTasks"; 

function App() {
  useEffect(() => {
    if (sessionStorage.getItem("loggedIn") !== "true") {
      window.location.href = "/login.html";
    }
  }, []);

  return <MondayTasks />;
}

export default App;
